import {
  Button,
  Grid,
  Inline,
  Input,
  Spinner,
  useFloatingMessage,
} from "@intility/bifrost-react";

import { useContext, useState } from "react";
import useSWR from "swr";
import { authorizedFetch } from "~/auth";
import { ModalContext } from "~/context/ModalContext";
import { Cluster } from "~/types";
import styles from "./deleteModal.module.css";

const baseAddress = import.meta.env["VITE_BACKEND_BASE"];

const DeleteModal = ({ clusterName }: { clusterName: string }) => {
  const [inputString, setInputString] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { mutate } = useSWR<Cluster[]>("clusters");

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const handleDelete = async () => {
    setIsFetching(true);

    await authorizedFetch(baseAddress + `/api/v1/clusters/${clusterName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        showFloatingMessage(
          <div className={styles.floatingMessage}>
            <span className={styles.floatingMessageTitle}>
              Cluster not deleted
            </span>
            <span>
              Cluster{" "}
              <span
                className={styles.floatingMessageClusterName}
                style={{ fontWeight: "900" }}
              >
                {clusterName}
              </span>{" "}
              could not be deleted. Please try again later.
            </span>
          </div>,
          { state: "warning", noIcon: true },
        );
      } else {
        showFloatingMessage(
          <div className={styles.floatingMessage}>
            <span className={styles.floatingMessageTitle}>Cluster deleted</span>
            <span>
              Cluster{" "}
              <span
                className={styles.floatingMessageClusterName}
                style={{ fontWeight: "900" }}
              >
                {clusterName}
              </span>{" "}
              has been successfully deleted.
            </span>
          </div>,
          { state: "success", noIcon: true },
        );
      }
    });

    setIsFetching(false);
    closeModal();
    await mutate();
  };

  const closeModal = () => {
    handleModal({});
    setIsFetching(false);
    setInputString("");
  };

  return (
    <Grid className={styles.modal}>
      <span>
        Are you sure you want to delete{" "}
        <span className={styles.clusterName}>{clusterName}</span>?
      </span>
      <Input
        className={styles.confirmationInput}
        label="Write cluster name to delete"
        placeholder="Cluster name"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
      />
      <Inline>
        <Inline.Separator />
        <Button
          className={styles.modalButton}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          className={styles.modalButton}
          variant="filled"
          state="alert"
          disabled={inputString !== clusterName || isFetching}
          onClick={() => {
            handleDelete();
          }}
        >
          {isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <span>Deleting</span>
              <Spinner size={14} />
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Inline>
    </Grid>
  );
};

export default DeleteModal;
