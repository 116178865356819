import { faGrid2, faList, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon, Input } from "@intility/bifrost-react";
import { useState } from "react";
import manageClusterCliImg from "~/assets/images/guides/manage-clusters-cli.png";
import manageClusterPortalImg from "~/assets/images/guides/manage-clusters-portal.png";
import whoAreWeImg from "~/assets/images/guides/who-are-we.png";
import securityImg from "~/assets/images/guides/security.jpg";
import supportImg from "~/assets/images/guides/support.jpg";
import highAvailabilityImg from "~/assets/images/guides/high-availability.jpg";
import monitoringImg from "~/assets/images/guides/monitoring.jpg";
import managedClusterImg from "~/assets/images/guides/managed-cluster.png";
import operationalResponsibilityImg from "~/assets/images/guides/operational-responsibility.jpg";
import { Guides } from "./components/Guides";
import styles from "./guidesPage.module.css";
import { cn } from "~/utils/clsx";

export type DisplayMode = "list" | "tile";

const GuidesPage = () => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>("list");
  const [searchValue, setSearchValue] = useState("");

  const filteredGuides = guides.filter((g) =>
    g.title.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <div className={styles.content}>
      <div className={styles.introBanner}>
        <div className={styles.textSection}>
          <h5 className="bf-h5">Hello! 👋</h5>
          <span>
            Here you will find articles and guides, and hopefully get answers to
            any questions you may have. Use the search field to find exactly
            what you are looking for, and choose between list and tile view.
          </span>
        </div>
        <div className={styles.inputSection}>
          <Input
            className={styles.searchInput}
            label="search"
            hideLabel
            clearable
            placeholder="Search"
            icon={faSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            iconButton
            rightIcon
          />
          <div className={styles.displayOptions}>
            <Button small variant="flat" onClick={() => setDisplayMode("list")}>
              <Icon
                icon={faList}
                className={cn(styles.displayIcon, {
                  [styles.active]: displayMode === "list",
                })}
              />
            </Button>

            <div className={styles.verticalDivider} />

            <Button small variant="flat" onClick={() => setDisplayMode("tile")}>
              <Icon
                icon={faGrid2}
                className={cn(styles.displayIcon, {
                  [styles.active]: displayMode === "tile",
                })}
              />
            </Button>
          </div>
        </div>
      </div>

      <Guides displayMode={displayMode} guides={filteredGuides} />
    </div>
  );
};

export default GuidesPage;

const guides = [
  {
    imgSrc: whoAreWeImg,
    imgAlt: "Two employees sitting at their desks",
    title: "Who are we?",
    ingress:
      "Our goal is to help you spend more time on development and less time on everything else. We take care of the technical stuff, so you can focus on the creative.",
    href: "https://article.intility.com/d6ffbd1d-b121-431c-460f-08dc846ac68b",
  },
  {
    imgSrc: manageClusterPortalImg,
    imgAlt: "Screenshot of the cluster creation portal user interface",
    title: "Manage clusters in the portal",
    ingress:
      "This guide will help you manage your cluster effectively using the portal",
    href: "https://article.intility.com/7f9cc811-02f7-47f0-39d8-08dc85ed70aa",
  },
  {
    imgSrc: manageClusterCliImg,
    imgAlt: "Screenshot of the icpctl CLI tool",
    title: "Manage clusters with the CLI",
    ingress:
      "This guide will help you understand how to create, list, and delete clusters using the command-line tool and icpctl.",
    href: "https://article.intility.com/51ec0d96-220b-4e66-402b-08dc346b24fd",
  },
  {
    imgSrc: securityImg,
    imgAlt: "Employee sitting at his desk",
    title: "Security",
    ingress:
      "We take responsibility for ensuring your Kubernetes infrastructure is secure and continuously monitor to handle security incidents.",
    href: "https://article.intility.com/a2771fb2-5f88-46cc-dc7f-08dc8467abab",
  },
  {
    imgSrc: supportImg,
    imgAlt: "Two employees at their desks talking to each other",
    title: "Support",
    ingress:
      "Intility ensures fast and efficient support for development teams through the integration of collaboration tools like Slack and Microsoft Teams.",
    href: "https://article.intility.com/c09c78f9-1f0b-42e6-9adc-08dc3464654f",
  },
  {
    imgSrc: highAvailabilityImg,
    imgAlt: "",
    title: "High Availability",
    ingress:
      "High Availability (HA) ensures that your applications are robust and always available, even when the infrastructure fails.",
    href: "https://article.intility.com/4e332997-c40b-4d71-4611-08dc846ac68b",
  },
  {
    imgSrc: monitoringImg,
    imgAlt: "",
    title: "Monitoring",
    ingress:
      "Intility is responsible for 24/7 monitoring and managing alarms on all resources, such as the OpenShift cluster and the Argo CD instance, that you create on the Developer Platform.",
    href: "https://article.intility.com/a52ff4bb-6c2b-4daa-9add-08dc3464654f",
  },
  {
    imgSrc: managedClusterImg,
    imgAlt: "",
    title: "Managed Cluster",
    ingress:
      "With our managed cluster, you get a complete solution that lets you focus on development while we take care of all the technical aspects.",
    href: "https://article.intility.com/6640b62d-babc-4a3b-12ab-08dc8469dd1f",
  },
  {
    imgSrc: operationalResponsibilityImg,
    imgAlt: "",
    title: "Intility's Operational Responsibility and Self-Service",
    ingress:
      "The Intility Developer Platform is designed to handle the increasing demands for automation, scalability, and abstraction in Kubernetes.",
    href: "https://article.intility.com/4e280e53-515e-48df-be7f-08dc366a584a",
  },
];
