import {
  AuthenticatedTemplate,
  MsalProvider,
  useMsalAuthentication,
} from "@azure/msal-react";
import { PropsWithChildren, useEffect } from "react";
import { instance, loginHint } from "./instance";
import { InteractionType } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";

export const loginRequest = {
  scopes: [
    "User.Read",
    "api://cd5aff56-575c-4e7e-b68e-3f67fa42eb31/user_impersonation",
  ],
  loginHint: loginHint,
};

const Template = ({ children }: PropsWithChildren) => {
  const { error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate("/login");
    }
  }, [error, navigate]);

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

export const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={instance}>
      <Template>{children}</Template>
    </MsalProvider>
  );
};
