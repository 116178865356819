import { faDown, faSpinner, faUp } from "@fortawesome/pro-solid-svg-icons";
import { AutoCol, Icon, Section, Spinner } from "@intility/bifrost-react";
import { useClusters } from "~/api/useClusterApi";
import manCluelessDark from "~/assets/man-clueless-dark.svg";
import womanGesturing from "~/assets/woman-gesturing.svg";
import OverviewCard from "./OverviewCard";
import styles from "./overviewSection.module.css";

interface OverviewSectionProps {
  className?: string;
}

const OverviewSection = ({ className }: OverviewSectionProps) => {
  const { data, isLoading, error } = useClusters();

  return (
    <Section className={`${className} ${styles.section}`}>
      <Section.Header>Overview</Section.Header>
      <Section.Content className={styles.sectionContent}>
        {isLoading ? (
          <div className={styles.loadingWrapper}>
            <Spinner className={styles.loadingSpinner} />
          </div>
        ) : error ? (
          <div className={styles.errorWrapper}>
            <h4 className="bf-h4">No cluster info</h4>
            <p>This information is not available right now</p>

            <img
              src={manCluelessDark}
              height={150}
              alt="Confused person"
              className={`from-large ${styles.errorIllustration}`}
            />
          </div>
        ) : data ? (
          <AutoCol>
            <OverviewCard
              dataValue={data?.length || 0}
              dataLabel="Total clusters"
              cardGraphics={
                <img src={womanGesturing} alt="Woman pointing at value" />
              }
              isHighlighted={true}
              centerGraphics={false}
            />
            <OverviewCard
              dataValue={
                data?.filter((c) => c.status.deployment.active === true)
                  .length || 0
              }
              dataLabel="Clusters deploying"
              cardGraphics={
                <div className={`${styles.cardIconBox} ${styles.dimmed}`}>
                  <Icon className={`bf-h3 ${styles.dimmed}`} icon={faSpinner} />
                </div>
              }
            />
            <OverviewCard
              dataValue={
                data?.filter((c) => c.status.ready.status === true).length || 0
              }
              dataLabel="Clusters ready"
              cardGraphics={
                <div className={`${styles.cardIconBox} ${styles.success}`}>
                  <Icon className={`bf-h3 ${styles.success}`} icon={faUp} />
                </div>
              }
            />
            <OverviewCard
              dataValue={
                data?.filter((c) => c.status.ready.status === false).length || 0
              }
              dataLabel="Clusters not ready"
              cardGraphics={
                <div className={`${styles.cardIconBox} ${styles.alert}`}>
                  <Icon className={`bf-h3 ${styles.alert}`} icon={faDown} />
                </div>
              }
            />
          </AutoCol>
        ) : null}
      </Section.Content>
    </Section>
  );
};

export default OverviewSection;
