import { cn } from "~/utils/clsx";
import styles from "./Tag.module.css";

interface TagProps {
  color?: "attn";
  children: React.ReactNode;
}

export const Tag = ({ color = "attn", children }: TagProps) => {
  return (
    <div
      className={cn("bf-satoshi", styles.tag, {
        [styles.attn]: color === "attn",
      })}
    >
      {children}
    </div>
  );
};
