import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { config } from "./config";
import { LOGIN_HINT_KEY } from "./constants";

/**
 * A PublicClientApplication instance
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const instance = new PublicClientApplication(config);
await instance.initialize();

// only get once on page load
export const loginHint = localStorage?.getItem(LOGIN_HINT_KEY) ?? undefined;

/**
 * Register event callback to set the active account atfer successful login
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md#active-account-apis
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
 */
instance.addEventCallback((message) => {
  if (
    message.eventType === EventType.LOGIN_SUCCESS ||
    message.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    if (message.payload && "account" in message.payload) {
      const account = message.payload.account;

      if (account) {
        instance.setActiveAccount(account);
        localStorage.setItem(LOGIN_HINT_KEY, account.username);
      }
    }
  }
});

export function logout() {
  localStorage.removeItem(LOGIN_HINT_KEY);
  instance.logoutRedirect();
}

export { instance };
