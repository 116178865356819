import { wrapCreateBrowserRouter } from "@sentry/react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import { ErrorPage } from "./pages/error/ErrorPage";
import HelpCenterPage from "./pages/helpCenter/HelpCenterPage";
import Home from "./pages/home/Home";
import { LandingPage } from "./pages/landing/landingPage/LandingPage";
import { SignInPage } from "./pages/signIn/SignInPage";
import { AppProvider, ProtectedApp, PublicApp } from "./providers/app";
import { AdminRoute } from "./routes/admin";
import { Clusters } from "./features/clusters/routes/Clusters";
import { NewCluster } from "./features/clusters/routes/NewCluster";

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <AppProvider>
          <Outlet />
        </AppProvider>
      }
    >
      <Route path="*" element={<ErrorPage code={404} />} />

      <Route element={<PublicApp />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<SignInPage />} />
      </Route>

      <Route element={<ProtectedApp />}>
        <Route path="/home" element={<Home />} />
        <Route path="/clusters" element={<Clusters />} />
        <Route path="/clusters/new" element={<AdminRoute />}>
          <Route index element={<NewCluster />} />
        </Route>
        <Route path="/helpcenter" element={<HelpCenterPage />} />
      </Route>
    </Route>,
  ),
);

export { router };
