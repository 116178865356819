import ClusterTable from "../components/clusterList/ClusterTable";
import CreateButton from "../components/clusterList/CreateButton";
import styles from "./clusters.module.css";

import PageBanner from "~/components/PageBanner";

export const Clusters = () => {
  return (
    <div className={styles.content}>
      <PageBanner
        title="Clusters"
        subTitle="Get an overview of your existing clusters, and create new ones"
        content={<CreateButton />}
        className={styles.banner}
      />

      <ClusterTable />
    </div>
  );
};
