import styles from "./newCluster.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Cluster,
  NewClusterForm,
  NewClusterRequestDTO,
  NewClusterStep,
  NodeSpecPreset,
} from "~/types";
import createRandomString from "~/utils/createRandomString";
import { isValidClusterName } from "~/utils/validations";
import { authorizedFetch } from "~/auth";
import { useFloatingMessage } from "@intility/bifrost-react";
import useSWR from "swr";
import BreadcrumbsHeader from "../components/newCluster/BreadcrumbsHeader";
import CreateClusterFooter from "../components/newCluster/CreateClusterFooter";
import CreateClusterMenu from "../components/newCluster/CreateClusterMenu";
import CreateClusterPane from "../components/newCluster/CreateClusterPane";

const steps = [
  {
    id: 0,
    name: "clusterName",
    title: "Cluster name",
  },
  {
    id: 1,
    name: "specifications",
    title: "Specifications",
  },
  {
    id: 2,
    name: "summary",
    title: "Summary",
  },
];

export const NewCluster = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<NewClusterStep>(steps[0]);
  const [formData, setFormData] = useState<NewClusterForm>({
    clusterName: "",
    clusterNameSuffix: createRandomString(6),
    nodeCount: 2,
    preset: NodeSpecPreset.Minimal,
  });

  const resetForm = () => {
    setFormData({
      clusterName: "",
      clusterNameSuffix: createRandomString(6),
      nodeCount: 2,
      preset: NodeSpecPreset.Minimal,
    });
  };

  const [isFetching, setIsFetching] = useState(false);
  const { showFloatingMessage } = useFloatingMessage();
  const { mutate } = useSWR<Cluster[]>("clusters");
  const baseAddress = import.meta.env["VITE_BACKEND_BASE"];

  const handleCreate = async () => {
    setIsFetching(true);
    const clusterName = formData.clusterName + "-" + formData.clusterNameSuffix;
    const newClusterReq = {
      name: clusterName,
      nodePools: [
        {
          preset: formData.preset,
          replicas: formData.nodeCount,
        },
      ],
    } as NewClusterRequestDTO;

    await authorizedFetch(baseAddress + `/api/v1/clusters/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newClusterReq),
    }).then((response) => {
      if (!response.ok) {
        // handle error
      } else {
        showFloatingMessage(
          <div className={styles.floatingMessage}>
            <span className={styles.floatingMessageTitle}>Cluster created</span>
            <span>
              Cluster{" "}
              <span
                className={styles.floatingMessageClusterName}
                style={{ fontWeight: "900" }}
              >
                {clusterName}
              </span>{" "}
              has been created successfully.
            </span>
          </div>,
          { state: "success", noIcon: true },
        );
      }
    });

    resetForm();
    setIsFetching(false);

    await mutate();

    navigate("/clusters");
  };

  return (
    <div className={styles.content}>
      <BreadcrumbsHeader />
      <div className={styles.wizard}>
        <div className={styles.menu}>
          <CreateClusterMenu
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CreateClusterPane
            activeStep={activeStep}
            formData={formData}
            setFormData={setFormData}
          />
          <CreateClusterFooter
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            cancelButtonOnClick={() => {
              navigate("/clusters");
              resetForm();
            }}
            createButtonOnClick={handleCreate}
            createButtonDisabled={!isValidClusterName(formData.clusterName)}
            createButtonFetching={isFetching}
          />
        </div>
      </div>
    </div>
  );
};
