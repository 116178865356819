import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import cpmLogo from "~/assets/cpm-logo.svg";
import groupOfPeople from "~/assets/group-of-people.svg";
import scalingKubernetes from "~/assets/images/landing/scaling-kubernetes-keda.png";
import { cn } from "~/utils/clsx";
import { Tag } from "../components/Tag";
import styles from "./LandingPage.module.css";
import { CustomerCarousel } from "./components/CustomerCarousel";
import threatDetection from "~/assets/images/landing/threat-detection.png";
import icpctl from "~/assets/images/landing/icpctl.gif";
import { Link } from "react-router-dom";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

export const LandingPage = () => {
  return (
    <div
      className={cn(
        "bf-darkmode bfc-base-bg bf-page-padding-x bf-satoshi",
        styles.pageContainer,
      )}
    >
      <div className={styles.pageContent}>
        <div className={styles.hero}>
          <div className={styles.heroHeader}>
            <Tag>Intility Developer Platform</Tag>

            <h1 className={cn("bf-h1", styles.headline)}>
              Spend more time{" "}
              <span className={styles.headlineEmphasis}>developing</span>{" "}
              features
            </h1>

            <img src={cpmLogo} className={cn(styles.backgroundLogo)} alt="" />
          </div>

          <div className={styles.getStartedSection}>
            <img src={groupOfPeople} alt="" loading="lazy" />

            <div className={styles.getStartedContent}>
              <div className={styles.getStartedTextContent}>
                <h2 className="bf-h2">Get started!</h2>
                <p>
                  Sign in to manage clusters, or get in touch to find the best
                  solution for you and your company
                </p>
              </div>

              <div className={styles.getStartedButtons}>
                <Link to="/login">
                  <Button
                    pill
                    variant="flat"
                    className={cn(styles.button, styles.signInButton)}
                  >
                    Sign in
                  </Button>
                </Link>

                <a href="mailto:dani.wold.kristiansen@intility.no">
                  <Button
                    pill
                    variant="flat"
                    className={cn(styles.button, styles.getInTouchButton)}
                  >
                    Get in touch
                    <Icon icon={faEnvelope} marginLeft />
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.layoutContainer}>
          <div className={styles.noWorriesContainer}>
            <h3 className="bf-h3">No infrastructure worries</h3>

            <p>
              With Intility Developer Platform, businesses can save time and
              focus on building and developing their own software. This service
              is a fully configured and fully managed developer infrastructure
              that includes all the functionality that businesses need to
              develop secure, modern applications.
              <br />
              <br />
              The platform includes the management of underlying infrastructure,
              support, incident management, vulnerability mitigation, and
              automatic documentation, as well as close collaboration with
              certified Kubernetes experts.
            </p>
          </div>

          <div className={styles.productivityContainer}>
            <img src={icpctl} alt="icpctl demonstration" loading="lazy" />

            <div className={styles.productivityTextContent}>
              <h3 className="bf-h3">
                Increase{" "}
                <span className={cn(styles.developerEmphasis)}>developer</span>{" "}
                productivity
              </h3>
              <p>
                Manage your developer environment with portal, CLI, or
                infrastructure as code.
              </p>
            </div>
          </div>

          <div className={styles.engineeringContainer}>
            <img
              src={scalingKubernetes}
              alt="Scaling Kubernetes apps with KEDA"
              loading="lazy"
            />

            <div className={styles.engineeringInfo}>
              <h3 className="bf-h3">
                Take a peek inside the machinery with our Engineering blog
              </h3>

              <p>
                Get a unique insight into the technical challenges our brightest
                developers and system owners are working on at the Intility
                platform.
              </p>

              <a
                href="https://engineering.intility.com/"
                className={styles.blogLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  pill
                  variant="flat"
                  className={cn(styles.button, styles.blogButton)}
                >
                  Go to our devblog
                  <Icon icon={faArrowRight} marginLeft />
                </Button>
              </a>
            </div>
          </div>

          <CustomerCarousel />

          <div className={styles.inDevelopmentContainer}>
            <Tag>In Development</Tag>

            <h3 className="bf-h3">The platform is in continous development</h3>

            <img src={threatDetection} alt="" loading="lazy" />

            <p>
              Staying secure will be even easier with vulnerability scans for
              all your software and infrastructure configurations, as well as
              advanced threat detection with an AI driven response and automated
              handling of severe threats.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
