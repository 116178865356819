import { Icon } from "@intility/bifrost-react";
import { Cluster } from "~/types";
import styles from "./clusterStatusCounts.module.css";
import { ClusterStatusIcon } from "~/constants";

export const ClusterStatusCounts = ({ clusters }: { clusters: Cluster[] }) => {
  const clusterStatuses = getClusterStatusCounts(clusters);

  return (
    <div className={styles.container}>
      {clusterStatuses.deploying > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.deploying}
            className={styles.deploymentStateIcon}
            color="var(--bfc-base-c-2)"
            marginRight
          />
          {clusterStatuses.deploying} deploying
        </span>
      )}

      {clusterStatuses.ready > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.ready}
            className={styles.deploymentStateIcon}
            color="var(--bfc-base-c-success)"
            marginRight
          />
          {clusterStatuses.ready} ready
        </span>
      )}

      {clusterStatuses.notReady > 0 && (
        <span>
          <Icon
            icon={ClusterStatusIcon.notReady}
            className={styles.deploymentStateIcon}
            color="var(--bfc-base-c-alert)"
            marginRight
          />
          {clusterStatuses.notReady} not ready
        </span>
      )}
    </div>
  );
};

const getClusterStatusCounts = (clusters: Cluster[]) => {
  const clusterStatuses = {
    deploying: 0,
    ready: 0,
    notReady: 0,
  };

  for (const cluster of clusters) {
    const clusterStatus = cluster.status;

    if (clusterStatus.deployment.active) {
      clusterStatuses.deploying++;
    } else if (clusterStatus.ready.status) {
      clusterStatuses.ready++;
    } else if (!clusterStatus.ready.status) {
      clusterStatuses.notReady++;
    }
  }

  return clusterStatuses;
};
