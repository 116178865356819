import { faTrashCan, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import useRole from "~/hooks/useRole";
import { Cluster } from "~/types";
import styles from "./clusterTable.module.css";
import { ClusterStatusIcon } from "~/constants";
import DeleteModal from "./DeleteModal";

const columnHelper = createColumnHelper<Cluster>();

export const clusterColumns = [
  columnHelper.accessor("name", {
    header: "Name",
  }),
  columnHelper.accessor("consoleUrl", {
    header: "Console URL",
    cell: function Cell(column) {
      const cluster = column.row.original;

      return (
        <a
          className={`bf-link ${styles.clusterUrl}`}
          href={cluster.consoleUrl}
          target="_blank"
          rel="noreferrer"
        >
          {cluster.consoleUrl}
        </a>
      );
    },
    meta: {
      fromSize: "medium",
    },
  }),
  columnHelper.accessor("status.ready.status", {
    header: "Status",
    cell: function Cell(column) {
      const cluster = column.row.original;

      return <ClusterStatus clusterStatus={cluster.status} />;
    },
  }),
  columnHelper.display({
    header: "",
    id: "delete",
    enableSorting: false,
    meta: {
      cellClassName: styles.iconCell,
    },
    cell: function Cell(column) {
      const { handleModal } = useContext(ModalContext);
      const role = useRole();

      if (!role.isAdmin) {
        return null;
      }

      const cluster = column.row.original;

      return (
        <Button
          variant="flat"
          state="neutral"
          pill
          onClick={() =>
            handleModal({
              content: <DeleteModal clusterName={cluster.name} />,
              header: (
                <h5 className="bf-h5">
                  <Icon
                    icon={faWarning}
                    className={styles.warningIcon}
                    marginRight
                    color="var(--bfc-base-c-alert)"
                  />
                  Delete cluster
                </h5>
              ),
            })
          }
        >
          <Icon icon={faTrashCan} size="sm" />
        </Button>
      );
    },
  }),
];

interface ClusterStatusProps {
  clusterStatus: Cluster["status"];
}

const ClusterStatus = ({ clusterStatus }: ClusterStatusProps) => {
  const readyStatus = clusterStatus.ready.status;
  const deploymentActiveStatus = clusterStatus.deployment.active;

  switch (true) {
    case deploymentActiveStatus: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.deploying}
            color="var(--bfc-base-c-2)"
            marginRight
            spin
          />
          In deployment
        </div>
      );
    }

    case readyStatus: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.ready}
            color="var(--bfc-base-c-success)"
            marginRight
          />
          Ready
        </div>
      );
    }

    case true || readyStatus === false: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.notReady}
            color="var(--bfc-base-c-alert)"
            marginRight
          />
          Not ready
        </div>
      );
    }

    default:
      return <></>;
  }
};
