import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback } from "react";
import flytoget from "~/assets/images/landing/flytoget.png";
import { cn } from "~/utils/clsx";
import styles from "./CustomerCarousel.module.css";
import elvia from "~/assets/images/landing/elvia.jpg";
import spaceNorway from "~/assets/images/landing/space-norway.jpg";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";

useEmblaCarousel.globalOptions = { loop: true };

export const CustomerCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className={cn(styles.carouselContainer, styles.embla)}>
      <Button
        pill
        variant="flat"
        noPadding
        className={cn(styles.carouselButton, styles.carouselBackButton)}
        onClick={scrollPrev}
      >
        <Icon fixedWidth icon={faArrowLeft} />
        <span hidden>Previous slide</span>
      </Button>

      <div className={cn(styles.embla__viewport)} ref={emblaRef}>
        <div className={cn(styles.embla__container)}>
          {customers.map((customer) => {
            return (
              <div key={customer.name} className={cn(styles.embla__slide)}>
                <div className={styles.carouselContentContainer}>
                  <div className={styles.carouselImageContainer}>
                    <img
                      src={customer.image}
                      alt=""
                      className={styles.carouselImage}
                    />
                  </div>

                  <div className={styles.carouselTextContainer}>
                    <h3>{customer.name}</h3>
                    <p>{customer.ingress}</p>

                    <ul>
                      {customer.bulletPoints.map((bulletPoint) => {
                        return (
                          <li key={bulletPoint}>
                            <Icon
                              icon={faArrowRightLong}
                              color="var(--bfc-base-c-attn)"
                              marginRight
                            />
                            {bulletPoint}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Button
          pill
          variant="flat"
          noPadding
          className={cn(styles.carouselButton, styles.carouselNextButton)}
          onClick={scrollNext}
        >
          <Icon fixedWidth icon={faArrowRight} />
          <span hidden>Next slide</span>
        </Button>
      </div>
    </div>
  );
};

const customers = [
  {
    name: "Flytoget",
    ingress: "Flytoget is using the developer platform",
    image: flytoget,
    bulletPoints: [
      "Software for gathering data from trains and handling tickets",
      "Runs third-party software and internally developed software on the Developer Platform",
    ],
  },
  {
    name: "Elvia",
    ingress: "Elvia is using the developer platform",
    image: elvia,
    bulletPoints: [
      "Grid operator for 50% of the Norwegian population with high security and availability requirements",
      "Runs third-party software and internally developed software on the Developer Platform",
    ],
  },
  {
    name: "Space Norway",
    ingress: "Space Norway is using the developer platform",
    image: spaceNorway,
    bulletPoints: [
      "Proprietary software developed for the operation and management of Norway's largest fleet of satellites and space infrastructure",
      "Data platform for reception, processing, and distribution of weather data across the world",
    ],
  },
];
