export const API = {
  IDP: {
    url: import.meta.env["VITE_BACKEND_BASE"],
    scope: "api://cd5aff56-575c-4e7e-b68e-3f67fa42eb31/user_impersonation",
  },
  Graph: {
    url: "https://graph.microsoft.com",
    scope: "User.Read",
  },
} as const;
