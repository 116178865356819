import {
  faCircleDown,
  faCircleUp,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";

export const ClusterStatusIcon = {
  ready: faCircleUp,
  notReady: faCircleDown,
  deploying: faSpinner,
} as const;

export const QueryKey = {
  Clusters: "clusters",
  ProfilePicture: "profilePicture",
} as const;
