import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Input, useBreakpoint } from "@intility/bifrost-react";
import { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { useClusters } from "~/api/useClusterApi";
import { DataTable } from "~/components/table/DataTable";
import { Cluster } from "~/types";
import CallToAction from "./CallToAction";
import { clusterColumns } from "./ClusterTable.columns";
import styles from "./clusterTable.module.css";
import { ClusterStatusCounts } from "./ClusterStatusCounts";

const ClusterTable = () => {
  const { data: clusters, isLoading } = useClusters();
  const toMedium = useBreakpoint(null, "medium");

  const [clusterFilter, setClusterFilter] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  return (
    <>
      {clusters?.length === 0 && !isLoading ? (
        <CallToAction />
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.header}>
              <h5 className={`bf-h5`}>All clusters</h5>
              <ClusterStatusCounts clusters={clusters ?? []} />
            </div>

            <div className={styles.searchInputContainer}>
              <Input
                className={styles.searchInput}
                placeholder="Look up cluster"
                label={undefined}
                icon={faSearch}
                clearable
                rightIcon
                value={clusterFilter}
                onChange={(e) => setClusterFilter(e.target.value)}
              />
            </div>

            <DataTable
              columns={clusterColumns}
              data={clusters ?? []}
              onPaginationChange={setPagination}
              state={{
                globalFilter: clusterFilter,
                pagination,
              }}
              initialState={{
                sorting: [{ id: "name", desc: false }],
              }}
              isLoading={isLoading}
              meta={{
                noBorder: true,
                limitExpandClick: true,
              }}
              ExpandedRowComponent={toMedium ? ExpandedClusterRow : undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

const ExpandedClusterRow = ({ rowData }: { rowData: Cluster }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "var(--bfs8)",
      }}
    >
      <span style={{ width: "50px", marginRight: "8px" }}>Console URL</span>
      <a
        className={`bf-link ${styles.clusterUrl}`}
        href={rowData.consoleUrl}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.consoleUrl}
      </a>
    </div>
  );
};

export default ClusterTable;
